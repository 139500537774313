<template>
  <div class="CooperVision">
    <div class="row pt-5 mt-5 pb-5 mt-5">
      <div class="col-12 text-center">
        <img
          src="../assets/brands/cooper-vision.webp"
          class="img-fluid w-25"
          alt=""
        />
      </div>
    </div>

    <div class="row mt-5 mb-5" v-for="item in products" :key="item.id">
      <div class="col-xl-4 col-lg-12">
        <h3>{{ item.name }}</h3>
      </div>
      <div class="col-xl-4 col-lg-12">
        <p>
          {{ item.desc }}
        </p>
      </div>
      <div class="col-xl-4 col-lg-12">
        <img v-bind:src="'../images/cooper/' + item.image" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CooperVision",
  props: {
    msg: String,
  },
  data() {
    return {
      products: [
        {
          name: "BIOFINITY",
          desc:
            "Biofinity monthly contact lenses use Aquaform Comfort Science technology to bring you an advanced level of comfort in a monthly disposable lens. Aquaform technology brings together two essential elements for wearers: high oxygen performance and a naturally wet lens material. Your eyes stay healthy and white from the oxygen, and lenses stay soft and deposit-resistant with the water-retaining lens material. Add in a lens design that focuses light better for higher resolution and depth of focus, and these Biofinity contacts make for a remarkable silicone hydrogel lens.",
          image: "biofinity.webp",
          id: 1,
        },
        {
          name: "BIOFINITY MULTIFOCAL",
          desc:
            "CooperVision's Biofinity Multifocal is a high-performance, monthly silicone hydrogel lens for people with presbyopia. This lens combines two unique technologies: Aquaform Comfort Science lens material and the proven Balanced Progressive Technology multifocal lens design.",
          image: "biofinity-multifocal.webp",
          id: 1,
        },
        {
          name: "BIOFINITY TORIC",
          desc:
            "For contact lens wearers with astigmatism, Biofinity Toric lenses are quickly becoming the preferred lens. Aquaform Comfort Science Technology has produced a lens that allows plenty of oxygen into your eyes, keeping them healthy and bright. These lenses are ultra efficient at retaining water, so all day long you experience a wet contact lens that resists drying and protein deposits. Perhaps most importantly, Biofinity Toric contact lenses fit you correctly. Those with astigmatism know how important a properly fitting, high performance silicone hydrogel contact lens can be, and these lenses won't disappoint.",
          image: "biofinity-toric.webp",
          id: 1,
        },
        {
          name: "EXPRESSIONS COLORS LENSES",
          desc:
            "Expressions Colors let you change your eye color with contact lenses. And since you want people to notice you and not just the color of your eyes, our lenses use a technologically advanced tinting process to produce more natural, realistic color changes. Choose from eight great colors: jade, blue, aqua, green, hazel, gray, blue topaz, and brown",
          image: "expressions-single.webp",
          id: 1,
        },
        {
          name: "EXPRESSIONS",
          desc:
            "These contact lenses can be worn throughout the day, and then removed in the evening before you go to sleep, cleaned with contact lens solution, and stored in a contact lens case overnight. These lenses should be used daily for up to a month, then replaced with a fresh pair the following month.",
          image: "expressions-6.webp",
          id: 1,
        },
        {
          name: "FREQUENCY ASPHERIC 55",
          desc:
            "Frequency 55 Aspheric optics provide you with enhanced vision quality by focusing light to a common focal point on the back of your eye, instead of a general area. The aspheric design gives you greater image resolution and an increased depth of focus. That means you’ll enjoy crisper, more precise vision in addition to comfort, easy, handling, and strong performance.",
          image: "frequency-55.webp",
          id: 1,
        },
        {
          name: "FREQUENCY XC",
          desc:
            "FREQUENCY XC contact lenses are made from a material that binds moisture and keeps contact lenses fresh. It’s also highly permeable, allowing oxygen to reach the eyes. Their aspherical design delivers sharp vision in difficult situations (e.g. driving at dusk or prolonged computer use) and corrects low-level astigmatism.",
          image: "frequency-xc.webp",
          id: 1,
        },
        {
          name: "FREQUENCY XCEL TORIC",
          desc:
            "If you have astigmatism and want crisp, clear vision, Frequency xcel toric may be for you. Frequency xcel toric contact lenses offer our patented UltraSync Technology. This gives the design the ability to ensure that you benefit from a stable lens with minimal rotation. Frequency xcel toric contact lenses also include a light blue handling tint, which makes them easier to see when inserting and removing. For those with a high level of astigmatism, you can still enjoy the benefits of Frequency xcel toric with our extended range of powers.",
          image: "frequency-xcel.webp",
          id: 1,
        },
        {
          name: "PROCLEAR",
          desc:
            "Proclear contact lenses, also known as Proclear Compatibles, use a unique technology making them resistant to dehydration, which helps your Proclear lenses stay moist and comfortable all day.",
          image: "proclear.webp",
          id: 1,
        },
        {
          name: "PROCLEAR 1 DAY",
          desc:
            "Thanks to CooperVision’s PC Technology, the Proclear 1 day contact lens contains water molecules within its structure to protect your eyes against dryness and provide long lasting comfort.",
          image: "proclear-1day.webp",
          id: 1,
        },
        {
          name: "PROCLEAR MULTIFOCAL",
          desc:
            "Proclear Multifocal contact lenses are the only lenses designed to address two common conditions experienced by those with aging eyes: Presbyopia and eye dryness. Only Proclear Multifocal contact lenses combine a superior lens design, Balanced Progressive Technology, with a unique lens material made using our patented PC Technology. The result is a lens that gives you great vision at every distance while staying moist and comfortable, all day long.",
          image: "proclear-multifocal.webp",
          id: 1,
        },
        {
          name: "PROCLEAR MULTIFOCAL TORIC",
          desc:
            "If you have astigmatism, you may experience blurry or distorted vision. This can happen at any age. But somewhere between the ages of 40 to 45, everyone’s vision blurs on objects that are close up, like a book or menu. This eye condition is called “presbyopia,” and it’s part of our eyes’ normal aging process. To clear up both conditions, you need lenses that correct your vision at any distance, like our Proclear multifocal toric contacts. They deliver clear vision whether you’re reading the newspaper or a sign in the distance.",
          image: "proclear-multifocal-toric.webp",
          id: 1,
        },
        {
          name: "PROCLEAR XR TORIC",
          desc:
            "If you have astigmatism, Proclear Toric soft contact lenses let you enjoy a level of comfort unrivaled by any other soft toric lens - a difference you'll especially notice the longer you wear your lenses throughout the day. This exceptional level of comfort is made possible by PC Technology, which creates a unique material containing molecules of phosphorylcholine (PC), a substance found naturally in human cell membranes. These molecules attract and surround themselves with water, keeping Proclear Toric lenses moist and comfortable, even after 12 hours of wear. For those with a high level of astigmatism, you can still enjoy the benefits of Proclear Toric with the extended (XR) range of powers.",
          image: "proclear-xr-toric.webp",
          id: 1,
        },
        {
          name: "PROCLEAR TORIC",
          desc:
            "If you have astigmatism, Proclear Toric soft contact lenses let you enjoy a level of comfort unrivaled by any other soft toric lens - a difference you'll especially notice the longer you wear your lenses throughout the day. This exceptional level of comfort is made possible by PC Technology, which creates a unique material containing molecules of phosphorylcholine (PC), a substance found naturally in human cell membranes. These molecules attract and surround themselves with water, keeping Proclear Toric lenses moist and comfortable, even after 12 hours of wear.",
          image: "proclear-toric.webp",
          id: 1,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
