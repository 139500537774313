<template>
  <div class="home">
    <div id="carouselExampleInterval" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">We Beat Any Quote</h1>
                  <p class="lead">
                    Bring in your written quote and <br />see the difference
                  </p>
                  <button class="btn  btn-success1" data-toggle="modal" data-target="#bookModal">
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1">
                <img src="../assets/we-beat1.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">
                    Seniors deserve the<br />
                    best
                  </h1>
                  <p class="lead">
                    Amazing Deals For Seniors Only
                  </p>
                  <button class="btn  btn-success1" data-toggle="modal" data-target="#bookModal">
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1">
                <img src="../assets/older-couple.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">EYECARE FOR THE<br />WHOLE FAMILY</h1>
                  <p class="lead">
                    TO FIT YOUR NEEDS AND YOUR BUDGET
                  </p>
                  <button class="btn  btn-success1" data-toggle="modal" data-target="#bookModal">
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1">
                <img src="../assets/family-couch.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">
                    Eye protection for a<br />
                    digital age
                  </h1>
                  <p class="lead">
                    PROTECT YOURSELF FROM<br />
                    THE DAMAGE OF BLUE LIGHT
                  </p>
                  <button class="btn  btn-success1" data-toggle="modal" data-target="#bookModal">
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1 d-flex justify-content-end">
                <img src="../assets/group-phone.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev pt-5" href="#carouselExampleInterval" role="button" data-slide="prev">
        <span aria-hidden="true" class="jumbotron-controls"><i
            class="fas fa-arrow-alt-circle-left text-dark fa-2x"></i></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next  pt-5" href="#carouselExampleInterval" role="button" data-slide="next">
        <span class="jumbotron-controls" aria-hidden="true"><i
            class="fas fa-arrow-alt-circle-right text-dark fa-2x"></i></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <main class="container-fluid">
      <section id="home-1" class="row bg-orange text-white">
        <h1 class="text-center w-100">
          EYESAVE OPTOMETRIST
        </h1>
        <p class="text-center">
          We have a wide variety of eyewear and sunglasses. We also have an in
          house brand available eg. Sass, Deco & Ziito which is inexpensive
          quality eyewear that suits your style and pocket. Our dedicated team
          is committed to quality eyecare. Designer brands as follows: Rayban,
          Nike, Silhouette, Vogue, Guess, etc.
        </p>
      </section>
      <section id="home-2" class="row text-center">
        <div class="col-12">
          <h1 class="text-uppercase">Comprehensive Optometric services</h1>
        </div>
        <div class="col-md-3">
          <img src="../assets/eye-exam.webp" class="img-fluid  home-service-img" alt="" />
          <h3>COMPREHENSIVE VISUAL EVALUATION</h3>
        </div>
        <div class="col-md-3">
          <img src="../assets/contacts.webp" class="img-fluid home-service-img" alt="" />
          <h3>CONTACT LENS ASSESSMENT</h3>
        </div>
        <div class="col-md-3">
          <img src="../assets/glaucoma.webp" class="img-fluid home-service-img" alt="" />
          <h3>GLAUCOMA TESTING</h3>
        </div>
        <div class="col-md-3">
          <img src="../assets/glasses.webp" class="img-fluid home-service-img" alt="" />
          <h3>spectacle adjustments</h3>
        </div>
      </section>
      <section id="home-3" class="row bg-orange text-white">
        <div class="col-12">
          <h1 class="text-center">OUR POLICY : WE BEAT ANY QUOTE</h1>
          <p>
            Our STANDBY policy is to beat any written quotation. Our extensive
            range for men and women, young and not so young, has been sourced
            direct from independent designer brands from all over the world. We
            have a wide variety of eyewear to suit everyone’s style and budget.
            From our in house brands Sass, Deco and Ziito which are inexpensive,
            high quality eyewear to the luxury brands we all know and love.
            Because of this, the range of styles are virtually endless - modern,
            designer, sleek, stylish, retro, vintage, classic, clean or
            professional. Rimless or screw less. Saddle or keyhole. Something
            light, something bold, something spirited, something mysterious.
            Everyone wants something different and our fully trained advisers
            are happy guide you through the practical details of frame, fit and
            comfort.
          </p>
        </div>
      </section>
      <section id="home-4" class="row">
        <div id="carousel-example-multi" class="carousel slide carousel-multi-item v-2 product-carousel"
          data-ride="carousel">
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active mx-auto">
              <div class="col-12 col-md-4 col-lg-2 mx-auto">
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <img src="../assets/brands/1.webp" class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 col-md-4 col-lg-2 mx-auto">
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <img src="../assets/brands/2.webp" class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 col-md-4 col-lg-2 mx-auto">
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <img src="../assets/brands/3.webp" class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 col-md-4 col-lg-2 mx-auto">
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <img src="../assets/brands/4.webp" class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 col-md-4 col-lg-2 mx-auto">
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <img src="../assets/brands/5.webp" class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 col-md-4 col-lg-2 mx-auto">
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <img src="../assets/brands/6.webp" class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 col-md-4 col-lg-2 mx-auto">
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <img src="../assets/brands/7.webp" class="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="test"></section>

      <section id="home-5" class="row ">
        <div class="col-12 text-center">
          <h1>BRANCHES</h1>
        </div>
        <div class="col-md-2 pb-5">
          <!-- <div class="form-check">
            <input
              v-model="branch"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="capetown"
            />
            <label
              class="form-check-label"
              for="exampleRadios1"
              :class="capetown"
            >
              CAPE TOWN
            </label>
          </div> -->
          <div class="form-check">
            <input v-model="branch" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4"
              value="grassypark" />
            <label class="form-check-label" for="exampleRadios4" :class="grassypark">
              GRASSY PARK
            </label>
          </div>
          <div class="form-check">
            <input v-model="branch" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3"
              value="kenilworth" />
            <label class="form-check-label" for="exampleRadios3" :class="kenilworth">
              KENILWORTH
            </label>
          </div>
          <div class="form-check">
            <input v-model="branch" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5"
              value="bluedowns" />
            <label class="form-check-label" for="exampleRadios5" :class="bluedowns">
              BLUEDOWNS
            </label>
          </div>
          <div class="form-check">
            <input v-model="branch" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios6"
              value="mitchells" />
            <label class="form-check-label" for="exampleRadios6" :class="mitchells">
              MITCHELLS PLAIN
            </label>
          </div>
          <div class="form-check">
            <input v-model="branch" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios7"
              value="atlantis" />
            <label class="form-check-label" for="exampleRadios7" :class="atlantis">
              ATLANTIS
            </label>
          </div>
          <div class="form-check">
            <input v-model="branch" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios8"
              value="eersterivier" />
            <label class="form-check-label" for="exampleRadios8" :class="eersterivier">
              EERSTERIVIER
            </label>
          </div>
          <div class="form-check">
            <input v-model="branch" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios9"
              value="steenberg" />
            <label class="form-check-label" for="exampleRadios9" :class="steenberg">
              STEENBERG
            </label>
          </div>
        </div>

        <div class="col-md-10">
          <div class="branch-details">
            <div class="row" v-if="branch === 'capetown'">
              <div class="col-md-6 branch-info">
                <h3>80 ST GEORGES MALL, OPP EDGARS</h3>
                <hr />
                <p>
                  Eyesave Cape Town<br />
                  <a href="tel: 0214223130">Tel: 0214223130</a><br />
                  <a href="https://wa.me/+27760395680" target="_blank">WhatsApp: 0760395680</a><br />
                  <a href="mailto:sgm@eyesave.co.za">Email: sgm@eyesave.co.za</a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 8:30am until 4pm Mon - Fri
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13242.86210735201!2d18.4212682!3d-33.9227204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43776a03d9a08fab!2sEye%20Save%20-%20Cape%20Town!5e0!3m2!1sen!2sza!4v1607575664834!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" class="branch-map"></iframe>
              </div>
            </div>

            <div class="row" v-if="branch === 'kenilworth'">
              <div class="col-md-6">
                <h3>60 LOCH ROAD KENILWORTH</h3>
                <hr />
                <p>
                  Eyesave Kenilworth<br />
                  <a href="tel: 0216719698">Tel: 0216719698</a><br />
                  <a href="https://wa.me/+27664566437" target="_blank">WhatsApp: 0664566437</a><br />
                  <a href="mailto:kc@eyesave.co.za">Email: kc@eyesave.co.za</a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 9am until 4:30pm Mon - Fri<br />
                  9am - 1pm Sat
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13232.609001636789!2d18.48301!3d-33.9886229!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1f85c496d666a5f8!2sEye%20Save%20-%20Kenilworth!5e0!3m2!1sen!2sza!4v1607575580380!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" class="branch-map"></iframe>
              </div>
            </div>

            <div class="row" v-if="branch === 'grassypark'">
              <div class="col-md-6">
                <h3>SHOP 1 RIDGEVILLE CENTRE, C/O VICTORIA & 2ND AVE</h3>
                <hr />
                <p>
                  Eyesave Grassy Park<br />
                  <a href="tel: 0217067247">Tel: 0217067247</a><br />
                  <a href="https://wa.me/+27791608756" target="_blank">WhatsApp: 0791608756</a><br />
                  <a href="mailto:gp@eyesave.co.za">Email: gp@eyesave.co.za
                  </a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 9am until 6pm Mon - Fri
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.9479348869218!2d18.491473715749414!3d-34.045206635668464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc43e03af8265f%3A0x773e31b021cee851!2sEye%20Save%20-%20Grassy%20Park!5e0!3m2!1sen!2sza!4v1607575706733!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" class="branch-map"></iframe>
              </div>
            </div>

            <div class="row" v-if="branch === 'bluedowns'">
              <div class="col-md-6">
                <h3>SHOP 9 CAVALIER CENTRE, HINDLE ROAD</h3>
                <hr />
                <p>
                  Eyesave Blue Downs<br />
                  <a href="tel: 0219095551">Tel: 0219095551</a><br />
                  <a href="https://wa.me/+27724191305" target="_blank">WhatsApp: 0724191305</a><br />
                  <a href="mailto:bluedowns@eyesave.co.za">Email: bluedowns@eyesave.co.za
                  </a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 9am until 5pm Mon - Fri
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.2549343186956!2d18.686567115748126!3d-33.985985032582846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc4c18eb523f99%3A0x129eb1bda57a31bd!2sEye%20Save%20-%20Blue%20Downs!5e0!3m2!1sen!2sza!4v1607575818101!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" class="branch-map"></iframe>
              </div>
            </div>

            <div class="row" v-if="branch === 'mitchells'">
              <div class="col-md-6">
                <h3>MERRYDALE CENTRE, LENTEGEUR</h3>
                <hr />
                <p>
                  Eyesave Mitchell's Plain<br />
                  <a href="tel: 0213711122">Tel: 0213711122</a><br />
                  <a href="https://wa.me/+27791320041" target="_blank">WhatsApp: 0791320041</a><br />
                  <a href="mailto:mp@eyesave.co.za">Email: mp@eyesave.co.za
                  </a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 9am until 3pm Mon - Fri
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.3473477484413!2d18.60828561574916!3d-34.03496003513438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc46033ca5ec65%3A0xeb04883f34ec3150!2sEye%20Save%20-%20Lentegeur!5e0!3m2!1sen!2sza!4v1607575767484!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" class="branch-map"></iframe>
              </div>
            </div>

            <div class="row" v-if="branch === 'atlantis'">
              <div class="col-md-6">
                <h3>WES FLEUR CIRCLE, ATLANTIS</h3>
                <hr />
                <p>
                  Eyesave Atlantis<br />
                  <a href="tel: 0215724353">Tel: 0215724353</a><br />
                  <a href="https://wa.me/+27665720564" target="_blank">WhatsApp: 0665720564</a><br />
                  <a href="mailto:atlantis@eyesave.co.za">Email: atlantis@eyesave.co.za
                  </a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 9am until 5:30pm Mon - Fri
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.6324203749177!2d18.493143315737786!3d-33.562925510680806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dccec4118fd8d1f%3A0x93cf3274544708b0!2sEye%20Save%20-%20Atlantis!5e0!3m2!1sen!2sza!4v1607575862082!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" class="branch-map"></iframe>
              </div>
            </div>

            <div class="row" v-if="branch === 'eersterivier'">
              <div class="col-md-6">
                <h3>SHOP N3.5 PLAIN STREET, EERSTERIVIER</h3>
                <hr />
                <p>
                  Eyesave eersterivier<br />
                  <a href="tel: 0219020107">Tel: 0219020107</a><br />
                  Fax: 0866118432<br />
                  <a href="https://wa.me/+27724193864" target="_blank">WhatsApp: 0724193864</a><br />
                  <a href="mailto:erm@eyesave.co.za">Email: erm@eyesave.co.za
                  </a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 9am until 5pm Mon - Fri
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.5857372148!2d18.72741881574847!3d-34.003172933477785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc4c1144da9ccb%3A0x2176199e6f362909!2sEye%20Save%20-%20Eerste%20River!5e0!3m2!1sen!2sza!4v1607575901132!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
            </div>

            <div class="row" v-if="branch === 'steenberg'">
              <div class="col-md-6">
                <h3 class="text-uppercase">shop 5, ABC building, 152 Military Rd, Steenberg, Cape Town, 7948</h3>
                <hr />
                <p>
                  Eyesave Steenberg<br />
                  <a href="tel: 021 879 4771">Tel: 021 879 4771</a><br />
                  <a href="https://wa.me/+27761779815" target="_blank">WhatsApp: 0761779815</a><br />
                  <a href="mailto:steenberg@eyesave.co.za">Email: steenberg@eyesave.co.za
                  </a>
                </p>
                <p class="font-weight-bold text-orange">
                  Operating Hours: 9am until 5pm Mon - Thurs<br>Fri 9-12 and 2-5pm <br>Sat 9-1pm
                </p>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13219.065561525891!2d18.4756874!3d-34.0755023!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc414cc938e03d%3A0x32140be182ddb12!2sEye%20Save%20-%20Steenberg!5e0!3m2!1sen!2sza!4v1712100005557!5m2!1sen!2sza"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Home",
  data() {
    return {
      branch: "grassypark",
    };
  },
  methods: {
    carousel() {
      $(".carousel.carousel-multi-item.v-2 .carousel-item").each(function () {
        var next = $(this).next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }
        next
          .children(":first-child")
          .clone()
          .appendTo($(this));

        for (var i = 0; i < 3; i++) {
          next = next.next();
          if (!next.length) {
            next = $(this).siblings(":first");
          }
          next
            .children(":first-child")
            .clone()
            .appendTo($(this));
        }
      });
    },
  },
  watch: {
    // call again the method if the route changes
    $route: "carousel",
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
    this.carousel();
    window.scrollTo(0, 0);
  },
};
</script>
