import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as VueGoogleMaps from 'vue2-google-maps';


Vue.config.productionTip = false;


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBJK1ook7o6-V0EBzVLmCTOHLISiYzOKBw'
  },
  installComponents: false
});
Vue.component('google-map', VueGoogleMaps.Map);
Vue.component(
  "Header",
  require("./components/Header.vue").default
);
Vue.component(
  "Footer",
  require("./components/Footer.vue").default
);
Vue.component(
  "Booking",
  require("./components/Booking.vue").default
);
Vue.component(
  "Gallery",
  require("./components/Gallery.vue").default
);
Vue.component(
  "Alcon",
  require("./components/Alcon.vue").default
);
Vue.component(
  "BauschLomb",
  require("./components/BauschLomb.vue").default
);
Vue.component(
  "Acuvue",
  require("./components/Acuvue.vue").default
);
Vue.component(
  "CooperVision",
  require("./components/CooperVision.vue").default
);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
