<template>
  <div>
    <div class="modal fade" id="bookModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content bg-blue">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Request an appointment
            </h5>
            <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
              <i class="fas fa-times-circle fa-2x"></i>
            </button>
          </div>
          <div class="modal-body bg-orange border-blue">
            <form>
              <div class="form-row">
                <div class="col-md-12 mb-5">
                  <label for="inputEmail3" class="">Name</label>
                  <input v-model="name" type="text" id="estimate-name" class="form-control" placeholder="name" />
                </div>
                <div class="col-md-12 mb-5">
                  <label for="inputEmail3" class="">Phone</label>
                  <input v-model="number" type="text" id="estimate-email" class="form-control"
                    placeholder="1234567890" />
                </div>
                <div class="col-md-12 mb-5">
                  <label for="inputEmail3" class="">Email</label>
                  <input v-model="email" type="text" id="estimate-email" class="form-control"
                    placeholder="name@email.co.za" />
                </div>
                <div class="col-md-12 mb-5">
                  <label for="inputEmail3" class="">Branch</label>
                  <select id="inputState" class="form-control" v-model="choice">
                    <option value="choose" selected>Choose...</option>
                    <option value="kenilworth">Kenilworth</option>
                    <option value="gp">Grassy Park</option>
                    <option value="bd">Bluedowns</option>
                    <option value="mp">Mitchells Plain</option>
                    <option value="atlantis">Atlantis</option>
                    <option value="er">Eersteriver</option>
                    <option value="steenberg">Steenberg</option>
                  </select>
                </div>
                <div class="col-md-12 mb-5" v-if="choice === 'other'">
                  <label for="inputEmail3" class="">Please describe the clean you require</label>
                  <textarea v-model="otherInfo" id="other" name="other" rows="4" cols="50">
                  </textarea>
                </div>
                <div class="col-md-12 mb-5">
                  <label for="inputEmail3" class="">Select a date</label>
                  <input v-model="date" type="date" id="estimate-email" class="form-control"
                    placeholder="name@email.co.za" />
                </div>
                <div class="col-12">
                  <button type="button" class="btn btn-link text-light p">
                    {{ error }}
                  </button>
                </div>
                <div class="col-md-4">
                  <button type="button" class="form-control btn-success estimate-submit" @click="sendBookingEmail()">
                    <div v-if="loading" class="spinner-border spinner-border-md"></div>
                    <span v-if="loading"> Sending</span>
                    <span v-else>{{ title }}</span>
                    <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                  </button>
                </div>

                <div class="col-md-3">
                  <input type="button" data-dismiss="modal" value="Cancel"
                    class="form-control bg-orange text-white estimate-submit" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Booking",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      choice: "choose",
      name: "",
      number: "",
      email: "",
      date: "",
      otherInfo: "",
    };
  },
  methods: {
    sendBookingEmail() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/sendBookingEyeSave.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: this.number,
              choice: this.choice,
              date: this.date,
              otherInfo: this.otherInfo,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.choice = "";
            this.date = "";
            this.otherInfo = "";
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
