<template>
  <div class="lenses">
    <div class="jumbotron jumbotron-fluid jumbo-bg">
      <div class="jumbo-flex">
        <div class="jumbo-flex1">
          <div class="flex-text1">
            <h1 class="display-4 with-hr text-white">EYEWARE</h1>
          </div>
        </div>
        <div class="jumbo-flex1">
          <div class="jumbo-img">
            <img src="../assets/eyeware.webp" class="pl-md-5 " alt="" />
          </div>
        </div>
      </div>
    </div>
    <section>
      <div class="row mt-5 mb-5">
        <!-- <div class="col-md-12 text-center"><h1>FRAMES</h1></div> -->
        <div class="col-md-12">
          <p>
            Walk into any Eyesave optometrist store, we will offer you a trendy
            frame to suit your budget. Our stand by policy is to beat any
            written quotation. Our extensive range for men and women, young and
            not so young, has been sourced direct from independent designer
            brands from all over the world. Our stylish budget brand would be
            Zitto, Our trend brand would be Deco and Sass and then there are
            designer and luxury brands
          </p>
          <p>
            And the range of styles is endless - modern, designer, sleek,
            stylish, retro, vintage, classic, clean or professional. Rimless or
            screwless. Saddle or keyhole. Something light, something bold,
            something spirited, something mysterious. Everyone wants something
            different and our fully trained advisers are happy guide you through
            the practical details of frame, fit and comfort.
          </p>
        </div>
      </div>
    </section>
    <section>
      <Gallery></Gallery>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Lenses",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
