<template>
  <footer class="bg-orange mt-5" id="footer">
    <div class="container-fluid py-5">
      <section class="mb-4">
        <div class="row">
          <div
            class="col-md-6 text-left text-white pb-5 mb-5 footer-contact-info"
          >
            <div>
              <h2
                class="font-weight-bold text-left my-5 text-uppercase text-white"
              >
                EYESAVE MISSION
              </h2>
              <p>
                We strive to source the best quality eyewear and aim to be at
                the forefront of the latest developments in eye care and
                products. We combine personal service with professional
                expertise to deliver eye care that you and your family deserve
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <form>
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <h2
                  class=" font-weight-bold text-left my-5 text-uppercase text-white"
                >
                  We would love to hear from you
                </h2>
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="name" class="">Name</label>
                    <input
                      v-model="name"
                      type="text"
                      id="name"
                      name="name"
                      class="form-control"
                    />
                  </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="email" class="">Phone</label>
                    <input
                      v-model="number"
                      type="text"
                      id="email"
                      name="email"
                      class="form-control"
                    />
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <label for="subject" class="">Email</label>
                    <input
                      v-model="email"
                      type="text"
                      id="subject"
                      name="subject"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="message">How can we help you</label>
                    <textarea
                      v-model="message"
                      type="text"
                      id="message"
                      name="message"
                      rows="4"
                      class="form-control md-textarea"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="text-center text-md-left footer-button">
                <button
                  type="button"
                  class="form-control btn-success estimate-submit col-4 btn"
                  @click="getInContact()"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-md"
                  ></div>
                  <span v-if="loading"> Sending</span>
                  <span v-else>{{ title }}</span>
                  <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                </button>
              </div>
              <!--Grid row-->
            </form>

            <div class="status"></div>
          </div>
        </div>
      </section>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Footer",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      name: "",
      number: "",
      email: "",
      message: "",
    };
  },
  methods: {
    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/sendContactEyeSave.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: this.number,
              message: this.message,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.message = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
