<template>
  <div class="lenses">
      <!-- <div class="jumbotron jumbotron-fluid jumbo-contactlenses">
      <div class="container-fluid">
        <div class="col-4">
          <h1 class="display-4 text-white">CONTACT LENSES</h1>
        </div>
        <div class="col-8"></div>
      </div>
    </div> -->
      <div class="jumbotron jumbotron-fluid jumbo-bg">
      <div class="jumbo-flex">
        <div class="jumbo-flex1">
          <div class="flex-text1">
            <h1 class="display-4 with-hr text-white">CONTACT LENSES</h1>
          </div>
        </div>
        <div class="jumbo-flex1">
          <div class="jumbo-img">
            <img src="../assets/contact-lenses2.webp" class="pl-md-5 " alt="" />
          </div>
        </div>
      </div>
    </div>
    <section>
      <div class="row mt-5 mb-5">
        <div class="col-md-12 text-center"><h1>CONTACT LENSES</h1></div>
        <div class="col-md-12">
          <p>
            Contact lenses are a miracle of modern times. The variety of contact
            lenses available may seem bewildering, but we are here to help you
            make the right choice to suit your needs. We use only the best
            contact lenses and provide quality care to ensure the optimum health
            of your eyes. Eyesave stocks top contact lens brands such as Bausch
            and Lomb, Cooper Vision, Ciba Vision Alcon. Our range assists with
            quality prescription contact lenses, as well as colour prescription
            contact lenses and accessory colour contact lenses.
          </p>
          <h4 class="pt-4">
            WHAT ARE THE BENEFITS OF CONTACT LENSES OVER SPECTACLES?
          </h4>
          <p class="pl-5">
          <ul class="pl-5">
            <li>
              Eyesave optometrist has contact lens experts who fit contact
              lenses easily for all prescriptions.
            </li>
            <li>
              Contact lenses will look better cosmetically than spectacles
              with thick lenses.
            </li>
            <li>Spectacles scratches while contact lenses dont.</li>
            <li>
              Spectacles are worn daily where as contact lenses can be used as
              day & night.
            </li>
            <li>
              Vision through contact lenses is better than that of spectacles
              due to spectacles being on and off from the eye while the contact
              lenses stays on.
            </li>
            <li>
              Contact lenses has wide variety of brands available to suit the
              patients lifestyle to daily sports or work etc..
            </li>
          </ul>
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">BRANDS</h2>
          <hr>
        </div>
      </div>
    </section>
    <section id="home-5" class="brands">
      <div class="row">
         <div class="form-check col-3">
            
            <input
              v-model="type"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="cooper"
            /><label
              class="form-check-label"
              for="exampleRadios1"
              :class="cooper"
            >
              <img src="../assets/brands/cooper-vision.webp" class="img-fluid w-50" alt="">
            </label>
          </div>
          
         <div class="form-check col-3">
            
            <input
              v-model="type"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="alcon"
            /><label
              class="form-check-label"
              for="exampleRadios2"
              :class="alcon"
            >
             <img src="../assets/brands/alcon.webp" class="img-fluid w-50" alt="">
            </label>
          </div>
         <div class="form-check col-3">
           
            <input
              v-model="type"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios3"
              value="bausch"
            /> <label
              class="form-check-label"
              for="exampleRadios3"
              :class="bausch"
            >
              <img src="../assets/brands/bausch-lomb.webp" class="img-fluid w-50" alt="">
            </label>
          </div>
         <div class="form-check col-3">
           
            <input
              v-model="type"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios4"
              value="acuvue"
            /> <label
              class="form-check-label"
              for="exampleRadios4"
              :class="acuvue"
            >
              <img src="../assets/brands/acuvue.webp" class="img-fluid w-50" alt="">
            </label>
          </div>

       
      </div>
      <div v-if="type === 'cooper'"><CooperVision></CooperVision></div>
      <div v-if="type === 'alcon'"><Alcon></Alcon></div>
      <div v-if="type === 'bausch'"><BauschLomb></BauschLomb></div>
      <div v-if="type === 'acuvue'"><Acuvue></Acuvue></div>
      
      
      
      
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Lenses",
  data() {
    return {
      type: ""
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
