<template>
  <div class="CooperVision">
    <div class="row pt-5 mt-5 pb-5 mt-5">
      <div class="col-12 text-center">
        <img
          src="../assets/brands/bausch-lomb.webp"
          class="img-fluid w-25"
          alt=""
        />
      </div>
    </div>

    <div class="row mt-5 mb-5" v-for="item in products" :key="item.id">
      <div class="col-xl-4 col-lg-12">
        <h3>{{ item.name }}</h3>
      </div>
      <div class="col-xl-4 col-lg-12">
        <p>
          {{ item.desc }}
        </p>
      </div>
      <div class="col-xl-4 col-lg-12">
        <img v-bind:src="'../images/bausch/' + item.image" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BauschLomb",
  props: {
    msg: String,
  },
  data() {
    return {
      products: [
        {
          name: "PUREVISION",
          desc:
            "PureVision contact lenses are the first lenses made using our unique AerGel material, delivering exceptional vision, with outstanding comfort and health.",
          image: "purevision.webp",
          id: 1,
        },
        {
          name: "PUREVISION MULTIFOCAL",
          desc:
            "If you're having trouble reading things up close, chances are, you're experiencing presbyopia, a naturally occurring vision condition that begins to affect most people in their 40's. But, just because you have presbyopia doesn't mean you need bifocals or reading glasses. Bausch & Lomb PureVision Multi-Focal contact lenses feature an innovative optical design allowing you to see clearly at all distances – near, far, and everywhere in-between.",
          image: "purevision-multifocal.webp",
          id: 1,
        },
        {
          name: "PUREVISION TORIC",
          desc:
            "Did you ever think that you couldn't wear contacts if you have astigmatism? Well think again. PureVision Toric Contact Lenses for Astigmatism are uniquely designed to meet the special vision correction needs of people with astigmatism.",
          image: "purevision-toric.webp",
          id: 1,
        },
        {
          name: "SOFLENS 1 DAY",
          desc:
            "SofLens Daily Disposable contact lenses combine outstanding all-day comfort with incredibly crisp, clear vision, especially at night. SofLens delivers all the fresh, new lens benefits of a daily disposable lens and much more – giving you the ultimate lens-wearing experience.",
          image: "soflens-1day.webp",
          id: 1,
        },
        {
          name: "SOFLENS 90",
          desc:
            "SofLens daily disposable contact lenses have an enhanced optical design that creates crisp, clear vision—especially in low light conditions, outstanding all-day comfort, and easy handling.",
          image: "soflens-90.webp",
          id: 1,
        },
        {
          name: "SOFLENS NATURAL COLOURS",
          desc:
            "SofLens Natural Colors are great for people who like to experiment with different looks every now and then. These opaque lenses suit a variety of eye colours, working especially well with those who have darker coloured eyes.",
          image: "soflens-natural-color.webp",
          id: 1,
        },
        {
          name: "SOFLENS MULTIFOCAL",
          desc:
            "The SofLens Multi-Focal Contact Lens may be prescribed for frequent/planned replacement or disposable wear. If you thought presbyopia and the loss of your reading vision meant the end of contact lenses, think again. Bausch & Lomb SofLens Multi-Focal contact lenses enable you to see well at all distances. The unique Natra-Sight™ Optics enables easy transitions across near, far, and all points in between, and a patented edge design provides all day comfort. Results from a clinical study show SofLens Multi-Focal contact lenses were patient-preferred 2 to 1 over a leading bifocal contact lens for overall visual quality. Wear on a daily basis and replace every two weeks.",
          image: "soflens-multifocal.webp",
          id: 1,
        },
        {
          name: "SOFLENS 59",
          desc:
            "SofLens 59 contact lenses deliver clear vision with the everyday comfort you have been looking for. SofLens 59 lenses are made from a protein resistant material that combines excellent performance, comfort and visual acuity.",
          image: "soflens-59.webp",
          id: 1,
        },
        {
          name: "SOFLENS 38",
          desc:
            "SofLens 38 contact lenses combine a high-performance with time-tested lens material that provides crisp, clear vision, comfort, and deposit resistance. The visibility tint and an inversion indicator, so that you can tell if the lens is inside out, make the SofLens 38 lens easy to handle and insert.",
          image: "soflens-38.webp",
          id: 1,
        },
        {
          name: "SOFLENS 66 TORIC",
          desc:
            "SofLens Toric Contact Lenses for Astigmatism is a two-week replacement lens designed for people with astigmatism. It has a unique design that is exceptionally easy to wear. It provides clear vision and exceptional comfort consistently from lens to lens.",
          image: "soflens-66.webp",
          id: 1,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
