import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Services from "../views/Services.vue";
import Specials from "../views/Specials.vue";
import Lenses from "../views/Lenses.vue";
import ContactLenses from "../views/ContactLenses.vue";
import EyeWare from "../views/EyeWare.vue";
import ChildEyeWare from "../views/ChildEyeWare.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/lenses",
    name: "Lenses",
    component: Lenses
  },
  {
    path: "/contactlenses",
    name: "ContactLenses",
    component: ContactLenses
  },
  {
    path: "/childeyecare",
    name: "ChildEyeWare",
    component: ChildEyeWare
  },
  {
    path: "/eyeware",
    name: "EyeWare",
    component: EyeWare
  },
  {
    path: "/specials",
    name: "Specials",
    component: Specials
  },
  {
    path: "/services",
    name: "Services",
    component: Services
  },
  {
    path: "/eyesave/*",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
