<template>
  <div class="services">
    <!-- <div
      id="carouselExampleInterval"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">We Beat Any Quote</h1>
                  <p class="lead">
                    Bring in your written quote and <br />see the difference
                  </p>
                  <button
                    class="btn  btn-success1"
                    data-toggle="modal"
                    data-target="#bookModal"
                  >
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1">
                <img src="../assets/we-beat1.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">
                    Seniors deserve the<br />
                    best
                  </h1>
                  <p class="lead">
                    Amazing Deals For Seniors Only
                  </p>
                  <button
                    class="btn  btn-success1"
                    data-toggle="modal"
                    data-target="#bookModal"
                  >
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1">
                <img src="../assets/older-couple.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">EYECARE FOR THE<br />WHOLE FAMILY</h1>
                  <p class="lead">
                    TO FIT YOUR NEEDS AND YOUR BUDGET
                  </p>
                  <button
                    class="btn  btn-success1"
                    data-toggle="modal"
                    data-target="#bookModal"
                  >
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1">
                <img src="../assets/family-couch.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-interval="5000">
          <div class="jumbotron jumbotron-fluid bg-white">
            <div class="jumbo-flex">
              <div class="jumbo-flex1">
                <div class="flex-text">
                  <h1 class="display-4">
                    Eye protection for a<br />
                    digital age
                  </h1>
                  <p class="lead">
                    PROTECT YOURSELF FROM<br />
                    THE DAMAGE OF BLUE LIGHT
                  </p>
                  <button
                    class="btn  btn-success1"
                    data-toggle="modal"
                    data-target="#bookModal"
                  >
                    <h3>REQUEST AN APPOINTMENT</h3>
                  </button>
                </div>
              </div>
              <div class="jumbo-flex1 d-flex justify-content-end">
                <img src="../assets/group-phone.webp" class="" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev pt-5"
        href="#carouselExampleInterval"
        role="button"
        data-slide="prev"
      >
        <span aria-hidden="true" class="jumbotron-controls"
          ><i class="fas fa-arrow-alt-circle-left text-dark fa-2x"></i
        ></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next  pt-5"
        href="#carouselExampleInterval"
        role="button"
        data-slide="next"
      >
        <span class="jumbotron-controls" aria-hidden="true"
          ><i class="fas fa-arrow-alt-circle-right text-dark fa-2x"></i
        ></span>
        <span class="sr-only">Next</span>
      </a>
    </div> -->
    <div class="jumbotron jumbotron-fluid jumbo-bg">
      <div class="jumbo-flex">
        <div class="jumbo-flex1">
          <div class="flex-text1">
            <h1 class="display-4 with-hr text-white">SPECIALS</h1>
          </div>
        </div>
        <div class="jumbo-flex1">
          <div class="jumbo-img">
            <img src="../assets/specials2.webp" class="pl-md-5 " alt="" />
          </div>
        </div>
      </div>
    </div>
    <section class="container">
      <div class="row">
        <div class="col-12 text-center"><h1>SUMMER SPECIALS</h1></div>
        <div class="col-md-4 pt-5 mt-4">
          <div class="card">
            <img
              src="../assets/single.webp"
              class="card-img-top"
              alt="Single Vision Special"
            />
             <div class="card-body d-flex justify-content-between flex-column h-100 bg-orange-light">
              <h2 class="card-title text-center pb-5 text-orange font-weight-bold">Single Vision Special</h2>
              <div class="card-text text-center">
                <h4 class="pb-3">What Is Included</h4>
                <p class="pb-5">
                  <ul class="list-unstyled">
                  <li>Eye Test</li>
                  <li>Frame</li>
                  <li>Lenses</li>
                </ul>
                </p>
                <h2 class="text-center"><small class="text-orange">From </small>R900</h2>
                <div class="col-12 pb-5 text-center"><p>T's & C's Apply</p></div>
              </div> 
              
                <div class="col-12 text-center"> <button
                    class="btn  btn-success1"
                    data-toggle="modal"
                    data-target="#bookModal"
                  >
                    <h3>BOOK AN<br>APPOINTMENT</h3>
                  </button></div> 
             
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-5 mt-4">
          <div class="card">
            <img
              src="../assets/bifocal.webp"
              class="card-img-top"
              alt="Bifocal Special"
            />
            <div class="card-body d-flex justify-content-between flex-column h-100 bg-orange-light">
              <h2 class="card-title text-center pb-5 text-orange font-weight-bold">Bifocal Special</h2>
              <div class="card-text text-center">
                <h4 class="pb-3">What Is Included</h4>
                <p class="pb-5">
                  <ul class="list-unstyled">
                  <li>Eye Test</li>
                  <li>Frame</li>
                  <li>Lenses</li>
                </ul>
                </p>
                <h2 class="text-center"><small class="text-orange">From </small>R990</h2>
                <div class="col-12 pb-5 text-center"><p>T's & C's Apply</p></div>
              </div> 
              
                <div class="col-12 text-center"> <button
                    class="btn  btn-success1"
                    data-toggle="modal"
                    data-target="#bookModal"
                  >
                    <h3>BOOK AN<br>APPOINTMENT</h3>
                  </button></div> 
             
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-5 mt-4">
          <div class="card">
            <img
              src="../assets/multi.webp"
              class="card-img-top"
              alt="Multifocal Special"
            />
            <div class="card-body d-flex justify-content-between flex-column h-100 bg-orange-light">
              <h2 class="card-title text-center pb-5 text-orange font-weight-bold">Multifocal Special</h2>
              <div class="card-text text-center">
                <h4 class="pb-3">What Is Included</h4>
                <p class="pb-5">
                  <ul class="list-unstyled">
                  <li>Eye Test</li>
                  <li>Frame</li>
                  <li>Lenses</li>
                </ul>
                </p>
                <h2 class="text-center"><small class="text-orange">From </small>R1950</h2>
                <div class="col-12 pb-5 text-center"><p>T's & C's Apply</p></div>
              </div> 
              
                <div class="col-12 text-center"> <button
                    class="btn  btn-success1"
                    data-toggle="modal"
                    data-target="#bookModal"
                  >
                    <h3>BOOK AN<br>APPOINTMENT</h3>
                  </button></div> 
             
            </div>
          </div>
        </div>
     
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
