<template>
  <div class="lenses">
    <div class="jumbotron jumbotron-fluid jumbo-bg">
      <div class="jumbo-flex">
        <div class="jumbo-flex1">
          <div class="flex-text1">
            <h1 class="display-4 with-hr text-white text-uppercase">Comprehensive School Eye Health</h1>
          </div>
        </div>
        <div class="jumbo-flex1">
          <div class="jumbo-img">
            <img src="../assets/eyeware.webp" class="pl-md-5 " alt="" />
          </div>
        </div>
      </div>
    </div>
    <section class="d-flex">

      <div class="w-75 pl-3">
        <p>
          Dear Parents
        </p><br>
        <p>
          To serve the best interest of your child, school eye health screening is a unique opportunity to provide
          access to cost effective comprehensive eye care for your child.
        </p>
        <p>
          The eye health screening will be offered to your child in the school at a fee of R60 per child, which
          includes an individual report. The fee will be billed to your school account.
        </p>
        <p>
          Eye health screening to your child is critically important for 4 reasons:
        <ol class="ml-5">
          <li>Awareness to neglected causes of childhood blindness and vision impairment in school age children.</li>
          <li>Early detection and referral of children with eye problems.</li>
          <li>The detection and treatment of common ocular problems, such as conjunctivitis, lid infections,
            irritation, sore and light sensitive eyes.</li>
          <li>Improving child’s vision contribute to improved education status, which in turn lead to better health in
            adult age.</li>
        </ol>
        </p>
        <p>
          The eye health screening tests are obligatory for all children from kindergarten to grade 12, excluding
          children who are currently having eyeglasses or are receiving ocular treatment.
        </p>
        <p>
          For communication, parents can use 066 4566 437 Eye save optometrist WhatsApp number.
        </p>
        <p>
          Thank you for your kind cooperation.
        </p>
        <br>
        <p>Eye save optometrist.</p>
        <p>Email: eyesave1@gmail.com</p>
        <p>60 Loch Road</p>
        <p>Kenilworth</p>
      </div>

      <div class="w-25"><img src="/gallery/childTest.webp" alt="" class="img-fluid">
        <a class="btn btn-success1" target="_blank" download href="/gallery/EyeSaveParentConsentForm.pdf">
          <h3 class="text-uppercase">Parental Consent Form</h3>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Lenses",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
