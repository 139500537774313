<template>
  <div class="CooperVision">
    <div class="row pt-5 mt-5 pb-5 mt-5">
      <div class="col-12 text-center">
        <img src="../assets/brands/acuvue.webp" class="img-fluid w-25" alt="" />
      </div>
    </div>

    <div class="row mt-5 mb-5" v-for="item in products" :key="item.id">
      <div class="col-xl-4 col-lg-12">
        <h3>{{ item.name }}</h3>
      </div>
      <div class="col-xl-4 col-lg-12">
        <p>
          {{ item.desc }}
        </p>
      </div>
      <div class="col-xl-4 col-lg-12">
        <img v-bind:src="'../images/acuvue/' + item.image" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Acuvue",
  props: {
    msg: String,
  },
  data() {
    return {
      products: [
        {
          name: "ACUVUE ADVANCE",
          desc:
            "Acuvue Advance contact lenses with Hydraclear are a moisture-rich, weekly contact lens that you can comfortably wear all day. These lenses are made of a silicone hydrogel material that permits 97% of available oxygen to pass through to your eye. Hydraclear technology makes them even better by mixing the wetting agent into the contact lens itself, so you get an all-day silky smooth wearing experience. To make them easier to find in saline solution, Acuvue Advance contacts are slightly tinted, but not enough to change your eye color. They block 93% of UV-A rays and 99% of UV-B rays, so you can rest knowing that your eyes are protected.",
          image: "acuvue-advance.webp",
          id: 1,
        },
        {
          name: "ACUVUE 1 DAY MOIST 30",
          desc:
            "30 pack contact lenses are an ideal choice for the lens wearer who doesn't want to deal with daily cleaning, storing, and discomfort that comes with extended wear lenses. Wear one pair a day and toss them out when the day is done. These contact lenses feature exclusive LACREON Technology, which means moisture is built into the lens. The moisture rich ingredient acts like natural tears, so no matter how many times you blink, you retain that cushioned, soft feeling. The lenses also protect your eyes from 82% of UV-A rays and 97% of UV-B rays. The contacts are a superior choice for the daily lens wearer.",
          image: "acuvue-moist-30.webp",
          id: 1,
        },
        {
          name: "ACUVUE 1 DAY MOIST 90",
          desc:
            "1-Day Acuvue Moist contact lenses are an ideal choice for the lens wearer who doesn't want to deal with daily cleaning, storing, and discomfort that comes with extended wear lenses. Wear one pair a day and toss them out when the day is done. These contact lenses feature exclusive LACREON Technology, which means moisture is built into the lens. The moisture rich ingredient acts like natural tears, so no matter how many times you blink, you retain that cushioned, soft feeling. The lenses also protect your eyes from 82% of UV-A rays and 97% of UV-B rays. 1-Day Acuvue Moist contacts are a superior choice for the daily lens wearer.",
          image: "acuvue-moist-90.webp",
          id: 1,
        },
        {
          name: "ACUVUE OASYS",
          desc:
            "Acuvue Oasys contact lenses with Hydraclear Plus provide an extremely comfortable contact lens wearing experience. These lenses have been designed to keep your eyes moist and comfortable all day long, using Hydraclear Plus technology. Even in dry conditions, you might forget you're wearing a lens at all. These Acuvue Oasys contacts come with an inside out marking so you can insert them properly every time. They block more than 99% of UVB and 95% of UVA-1 rays, the highest levels you'll find on the market today. And with dryness reducing Hydraclear Plus technology, these are the contact lenses for anyone looking to put down the rewetting drops and take off the glasses.",
          image: "acuvue-oasys.webp",
          id: 1,
        },
        {
          name: "ACUVUE OASYS ASTIGMATISM",
          desc:
            "If you thought you couldn't wear contact lenses because of astigmatism, the Acuvue Oasys for Astigmatism contact lenses are for you. The irregular curvature of the eye that characterizes astigmatism might have historically made finding contact lenses difficult, but Acuvue's Accelerated Stabilization Design technology solves that problem. These lenses won't rotate when you blink or move your eyes around quickly, so when you're active you won't have to worry about blurred vision. Add Hydraclear Plus technology that keeps your contact lenses wet and smooth, and you have a formula for a comfortable astigmatism lens like no other.",
          image: "acuvue-oasys-astigmatism.webp",
          id: 1,
        },
        {
          name: "ACUVUE 2",
          desc:
            "Acuvue 2 contact lenses have consistently been the best-selling hydrogel lens since their release in 1999. It's no surprise, as these lenses are very easy to insert and remove, and they help make your vision clear and crisp. Acuvue 2 is a UV blocking lens for those who like to get out and be active. The visibility tint makes them easy to find in saline solution, and once they're in you'll notice they are so comfortable you'll forget all about them. On top of all that, 7 out of 10 people with Acuvue 2 contacts achieve 20/20 vision or better.",
          image: "acuvue-2.webp",
          id: 1,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
