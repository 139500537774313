<template>
  <div class="CooperVision">
    <div class="row pt-5 mt-5 pb-5 mt-5">
      <div class="col-12 text-center">
        <img src="../assets/brands/alcon.webp" class="img-fluid w-25" alt="" />
      </div>
    </div>

    <div class="row mt-5 mb-5" v-for="item in products" :key="item.id">
      <div class="col-xl-4 col-lg-12">
        <h3>{{ item.name }}</h3>
      </div>
      <div class="col-xl-4 col-lg-12">
        <p>
          {{ item.desc }}
        </p>
      </div>
      <div class="col-xl-4 col-lg-12">
        <img v-bind:src="'../images/alcon/' + item.image" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alcon",
  props: {
    msg: String,
  },
  data() {
    return {
      products: [
        {
          name: "AIR OPTIX",
          desc:
            "Air Optix Aqua contact lenses are made to keep your eyes comfortable and moisture rich. This lens is incredibly breathable, allowing five times more oxygen through than other lenses. Air Optix uses TriComfort Technology not only to let the contact lens breathe and retain moisture, but also to resist deposits and keep the lenses comfortable and wearable for up to one month. Air Optix Aqua contacts are ideal for people who've had trouble with other lenses, have sensitive eyes, or just need more comfort from their lenses.",
          image: "air-optix.webp",
          id: 1,
        },
        {
          name: "AIR OPTIX FOR ASTIGMATSIM",
          desc:
            "Air Optix for Astigmatism contact lenses let you see clearly and comfortably, without the discomfort and dryness that accompanies astigmatism. The TriComfort Technology found in these lenses allows oxygen to flow continuously, keeping your eyes feeling healthy and natural. You'll also notice that your eyes feel moist and smooth, the result of patented Air Optix technology that helps the lenses retain moisture and resist deposits. Your astigmatism no longer means you have to put up with blurred vision or eyeglasses.",
          image: "air-optix-astigmatism.webp",
          id: 1,
        },
        {
          name: "AIR OPTIX MULTIFOCAL",
          desc:
            "Whether you're reading, driving or working on a computer, you can enjoy crisp, clear vision with AIR OPTIX AQUA MULTIFOCAL contact lenses even if approaching 40 or over. They're designed with TriComfort Technology to provide breathability and moisture retention, while resisting deposit buildup. At last, a lens that provides a smooth transition as your eyes move naturally from one focal distance to another - near, intermediate, and far.",
          image: "air-optix-multifocal.webp",
          id: 1,
        },
        {
          name: "DAILIES",
          desc:
            "30 pack advanced all day comfort that gives Instantly fresh and comfortable at insertion. Moisturizes with every blink and refreshes all day. Brand new pair every day with no cleaning or disinfecting involved and no deposit build-up.",
          image: "dailies.webp",
          id: 1,
        },
        {
          name: "DAILIES 90",
          desc:
            "Advanced all day comfort that gives Instantly fresh and comfortable at insertion. Moisturizes with every blink and refreshes all day. Brand new pair every day with no cleaning or disinfecting involved and no deposit build-up.",
          image: "dailies-90.webp",
          id: 1,
        },
        {
          name: "AIR OPTIX NIGHT & DAY",
          desc:
            "Air Optix Night & Day Aqua contact lenses are the only lenses that can claim the highest oxygen transmissibility of any lens on the market today. That means you can take a quick nap in them, or you can sleep in them and they'll still retain their enduring comfort. In fact, the FDA has approved these contacts for up to 30 nights of continuous wear. If you're too busy to pay attention to your contact lenses every day, choose Air Optix Night & Day Aqua.",
          image: "air-optix-nd.webp",
          id: 1,
        },
        {
          name: "FRESHLOOK",
          desc:
            "If you have ever wanted to subtly enhance your eye color, Freshlook Colorblends contact lenses are the way to do it. Using a patented 3-in-1 technology to combine three colors into one, your eyes will take on a new deep color while retaining their natural appearance. There are a variety of dramatic new shades to choose from including Sterling Gray, Brilliant Blue, and Gemstone Green. Or choose from one of the classic hues, like green, honey, gray, blue, turquoise, amethyst, brown, Pure Hazel, or True Sapphire. Freshlook Colorblends contacts are a 1-2 week disposable lens that you can wear every day or just for that special occasion.",
          image: "freshlook.webp",
          id: 1,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
