<template>
  <div>
    <div class="row" id="gallery">
      <div class="col-6 col-md-3 col-lg-2 pb-5" v-for="image in images" :key="image.src">
        <img class="w-100" :src="image.src" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        { src: "/gallery/image1.webp" },
        { src: "/gallery/image2.webp" },
        { src: "/gallery/image3.webp" },
        { src: "/gallery/image10.webp" },
        { src: "/gallery/image8.webp" },
        { src: "/gallery/image9.webp" },
        { src: "/gallery/image11.webp" },
        { src: "/gallery/image12.webp" },
        { src: "/gallery/image13.webp" },
        { src: "/gallery/image4.webp" },
        { src: "/gallery/image14.webp" },
        { src: "/gallery/image15.webp" },
        { src: "/gallery/image16.webp" },
        { src: "/gallery/image17.webp" },
        { src: "/gallery/image6.webp" },
        { src: "/gallery/image18.webp" },
        { src: "/gallery/image5.webp" },
        { src: "/gallery/image7.webp" },
        { src: "/gallery/image19.webp" },
        { src: "/gallery/image20.webp" },
      ],
    };
  },
};
</script>
