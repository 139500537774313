<template>
  <div class="lenses">
    <!-- <div class="jumbotron jumbotron-fluid jumbo-lenses">
      <div class="container-fluid">
        <div class="col-4 d-flex align-items-center">
          <h1 class="display-4 text-white">LENSES</h1>
        </div>
        <div class="col-8"></div>
      </div>
    </div> -->
    <div class="jumbotron jumbotron-fluid jumbo-bg">
      <div class="jumbo-flex">
        <div class="jumbo-flex1">
          <div class="flex-text1">
            <h1 class="display-4 with-hr text-white">LENSES</h1>
          </div>
        </div>
        <div class="jumbo-flex1">
          <div class="jumbo-img">
            <img src="../assets/lenses.webp" class="pl-md-5 " alt="" />
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="row mt-5 mb-5">
        <!-- <div class="col-md-12 text-center"><h1>LENSES</h1></div> -->
        <div class="col-md-12">
          <p>
            Eye Save offers the latest in lens technology providing our clients
            with options of Single vision, Bi focal and Multi focal lenses.
            Quality anti-reflective coatings provide protection from artificial
            light, perfect for night driving and computer users. We provide
            lighter and thinner materials so your lenses look as good as your
            frames do.
          </p>
          <p>
            Selected stores have a ‘cut and fit’ facility that can help turn
            around lenses in the same day. Generally our turn around time is
            7-10 working days, however there are instances that we can deliver
            within 48 hours.
          </p>
        </div>
      </div>
    </section>
    <section class="">
      <hr />
      <h2 class="text-center">LENSE TYPES</h2>
    </section>
    <section>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>SINGLE VISION</h2></div>
        <div class="col-md-4">
          <p>
            Single vision lenses are prescribed if you need correction for one
            field of vision, either for distance, intermediate (computer), or
            items up close (near vision). Single vision has the same optical
            focal point or correction over the entire area of the lens.
          </p>
        </div>
        <div class="col-md-4 text-center">
          <img
            src="../assets/single-vision-lenses.webp"
            class="img-fluid w-50 "
            alt=""
          />
          <h4 class="text-center">Single Vision Lens</h4>
        </div>
      </div>
    </section>
    <section class="">
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>BIFOCAL</h2></div>
        <div class="col-md-4">
          <p>
            Bifocal eyeglasses in which each lens is made up of two segments of
            different refractive powers, or strength. Generally, the upper part
            of the lens is used for ordinary or distant vision, and the smaller,
            lower section for near vision, for close work such as reading or
            sewing. Most commonly prescribed to people with presbyopia.
          </p>
        </div>
        <div class="col-md-4 text-center">
          <img
            src="../assets/bifocal-lenses.webp"
            class="img-fluid w-50 "
            alt=""
          />
          <h4 class="text-center">Bifocal Lens</h4>
        </div>
      </div>
    </section>
    <section class="">
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>MULTIFOCAL</h2></div>
        <div class="col-md-4">
          <p>
            A multifocal lens is a lens that contains two or more prescriptions
            for correcting vision at different distances. These include
            bifocals, trifocals, progressives and special occupational lenses.
            Multifocals are designed to help people see well at various
            distances, especially as they age.
          </p>
        </div>
        <div class="col-md-4 text-center">
          <img
            src="../assets/multifocal-lenses.webp"
            class="img-fluid w-50 "
            alt=""
          />
          <h4 class="text-center">Multifocal Lens</h4>
        </div>
      </div>
    </section>
    <section class="">
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>LENSE COATING</h2></div>
        <div class="col-md-8">
          <p>
            An anti-reflective or anti-reflection (AR) coating is a type of
            optical coating applied to the surface of lenses and other optical
            elements to reduce reflection. In typical imaging systems, this
            improves the efficiency since less light is lost.
          </p>
          <p>
            An optical coating is one or more thin layers of material deposited
            on an optical component such as a lens or mirror, which alters the
            way in which the optic reflects and transmits light.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Lenses",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
