<template>
  <div class="header">
    <nav class="navbar navbar-expand-xl navbar-light bg-white fixed-top">
      <router-link class="navbar-brand" to="/"
        ><img src="../assets/logo-orange.svg" class="img-fluid logo" alt=""
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <router-link
              class="nav-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              to="/services"
              >SERVICES</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              to="/specials"
              >SPECIALS</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              to="/eyeware"
              >EYEWARE</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              to="/lenses"
              >LENSES</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contactlenses"
              >CONTACT LENSES</router-link
            >
          </li>
          <li>
            <ul class="header-social">
              <li class="nav-item">
                <a
                  class="nav-link"
                  target="_blank"
                  href="https://www.facebook.com/eyesavesa"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  target="_blank"
                  href="https://www.instagram.com/eyesaveoptometristsa/"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li class="nav-item">
                <div class="dropdown">
                  <button
                    class="btn btn-link nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fab fa-whatsapp"></i>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <!-- <a
                      class="dropdown-item"
                      href="https://wa.me/+27760395680"
                      target="_blank"
                      >CAPE TOWN</a
                    > -->
                    <a
                      class="dropdown-item"
                      href="https://wa.me/+27664566437"
                      target="_blank"
                      >KENILWORTH</a
                    >
                    <a
                      class="dropdown-item"
                      href="https://wa.me/+27791320041"
                      target="_blank"
                      >MITCHELLS PLAIN</a
                    >
                    <a
                      class="dropdown-item"
                      href="https://wa.me/+27791608756"
                      target="_blank"
                      >GRASSY PARK</a
                    >
                    <a
                      class="dropdown-item"
                      href="https://wa.me/+27724191305"
                      target="_blank"
                      >BLUEDOWNS</a
                    >
                    <a
                      class="dropdown-item"
                      href="https://wa.me/+27665720564"
                      target="_blank"
                      >ATLANTIS</a
                    >
                    <a
                      class="dropdown-item"
                      href="https://wa.me/+27724193864"
                      target="_blank"
                      >EERSTERIVIER</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
