<template>
  <div class="services">
    <!-- <div class="jumbotron jumbotron-fluid jumbo-service">
      <div class="container-fluid">
        <div class="col-4 d-flex align-items-center">
          <h1 class="display-4 text-white">SERVICES</h1>
        </div>
        <div class="col-8"></div>
      </div>
    </div> -->
    <div class="jumbotron jumbotron-fluid jumbo-bg">
      <div class="jumbo-flex">
        <div class="jumbo-flex1">
          <div class="flex-text1">
            <h1 class="display-4 with-hr text-white">OUR SERVICES</h1>
          </div>
        </div>
        <div class="jumbo-flex1">
          <div class="jumbo-img">
            <img src="../assets/services-img.webp" class="pl-md-5 " alt="" />
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>COMPREHENSIVE VISUAL EVALUATION</h2></div>
        <div class="col-md-4">
          <h2>VISUAL ACUITY TESTS</h2>
          <hr />
          <p>
            Among the first tests performed in a comprehensive eye exam are
            visual acuity tests that measure the sharpness of your vision. These
            usually are performed using a projected eye chart to measure your
            distance visual acuity and a small, hand-held acuity chart to
            measure your near vision.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/Eye-Exam-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>CONTACT LENS CONSULTATION</h2></div>
        <div class="col-md-4">
          <h2>CONTACT LENS ASSESSMENT</h2>
          <hr />
          <p class="font-weight-bold">
            Eye Health Assessment
          </p>
          <p>
            In this first appointment you will have an eye health assessment
            which usually takes around forty minutes to an hour. It is
            particularly important that the tissue covering the front of your
            eyes (cornea) is deemed healthy. This is because contact lenses are
            placed over this area. Contact Lens Fitting Once you're cleared for
            wearing contact lenses by your optometrist, eye measurements will be
            taken. After discussing the different options available to you, a
            particular contact lens will be recommended. This will range from
            daily disposables, fortnightly wear, monthly wear and extendend wear
            (sleep-in) contact lenses. You will most likely be provided with a
            trial set at this point so you can give your new lenses a test run.
          </p>
          <br />
          <p class="font-weight-bold">
            Contact Lens Training - Insertion, Removal & Care
          </p>
          <p>
            Either immediately following your contact lens fitting, or a day or
            two later, one of your optometrist's staff members will take you
            through the process of how to insert, remove and care for your
            contact lenses. This can take some people longer than others to
            master, however, you can be confident that you won't leave until
            you're satisfied that you can do it on your own.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/contact-lenses-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5 ">
        <div class="col-md-4"><h2>GLAUCOMA TESTING</h2></div>
        <div class="col-md-4">
          <p>
            Early detection, through regular and complete eye exams, is the key
            to protecting your vision from damage caused by glaucoma. A complete
            eye exam includes five common tests to detect glaucoma.It is
            important to have your eyes examined regularly. Your eyes should be
            tested: before age 40, every two to four years from age 40 to age
            54, every one to three years from age 55 to 64, every one to two
            years after age 65, every six to 12 months. Anyone with high risk
            factors should be tested every year or two after age 35.
          </p>
          <p>
            A Comprehensive Glaucoma Exam. To be safe and accurate, five factors
            should be checked before making a glaucoma diagnosis: Tonometry,
            Ophthalmoscopy, Perimetry, Gonioscopy, Pachymetry
          </p>
          <p>
            Regular glaucoma check-ups include two routine eye tests: tonometry
            & ophthalmoscopy.
          </p>
        </div>
        <div class="col-md-4 ">
          <img
            src="../assets/services/glaucoma-testingl-small.jpg"
            class="img-fluid "
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>COLOUR VISION TESTING</h2></div>
        <div class="col-md-4">
          <h2>SCREENING COLOR BLIND TESTS</h2>
          <hr />
          <p>
            The most widely used screening test for color blindness is the
            Ishihara Color Vision Test. The test is named after Japanese
            ophthalmologist Shinobu Ishihara (1879-1963), who devised the
            procedure and first published a description of it in 1917.The
            Ishihara Color Vision Test consists of a booklet, each page
            containing a circular pattern (or "plate") comprising many dots of
            various colors, brightness and sizes. The seemingly random colored
            dots are arranged in such a fashion that a person with normal color
            vision will see a single-digit or two-digit number within the array
            of dots, while a colorblind person will either be unable to see a
            number or will see a different number than the one seen by a person
            with normal color vision.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/colour-vision-testing-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>ADJUSTMENT</h2></div>
        <div class="col-md-4">
          <p>
            spectacle & sunglass sales and adjustments
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/eyetest-for-pensioners-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>LENSES</h2></div>
        <div class="col-md-4">
          <p>
            full range of contact lenses for sale
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/comprehensive-eye-test-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>FREE VISUAL SCREENING FOR CHILDREN</h2></div>
        <div class="col-md-4">
          <h2>WHAT IS VISION SCREENING?</h2>
          <hr />
          <p>
            Vision screening is an efficient and cost-effective method to
            identify children with visual impairment or eye conditions that are
            likely to lead to visual impairment so that a referral can be made
            to an appropriate eye care professional for further evaluation and
            treatment.How is vision screening performed?There are a number of
            methods used to screen a child's vision. The method chosen is
            largely dependent on the age of the child being screened and the
            experience of the examiner. Several methods of vision screening are
            discussed below:
          </p>
          <p>
            Inspection of the eye, pupils and red reflex This method can be used
            on children of all ages. At each well child visit, the examiner uses
            a flashlight to inspect the eyes for abnormality of shape or
            structure and to detect irregularity in pupil shape. The pupil
            constricts (become smaller) in bright light and dilates (become
            larger) in the dark, and both pupils are the same size. An
            ophthalmoscope is used to observe the red reflex of the eye. The red
            reflex is a reflection from the lining of the inside of the eye that
            causes the pupil to look red in photographs. The red reflex should
            be bright in both eyes and equal.
          </p>
          <p>
            Photoscreening This is an automated technique that uses the red
            reflex to identify many types of eye problems. An advantage of this
            screening is that it is quick and thus useful in very young
            children. The newest generation of photoscreeners provide immediate
            information about the eye condition. There may be an extra fee for
            this testing when performed at a doctor's office. Some community
            screenings use this method.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/visionscreening-for-children-small (1).jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>SCREENING FOR DRIVERS LICENCE</h2></div>
        <div class="col-md-4">
          <p>
            You have to bring a valid Identification document (ID) with. The
            screening is done free of charge.The law stipulates that everyone
            renewing their driver's licences must have their vision screened.
            Instead of waiting in long queues, you can have your screening
            conducted by an optometrist.This project is conducted in
            co-operation with and approved by the licensing departments and the
            national Department of Transport. The screening does not replace a
            full eye examination. It will, however, reveal any potential
            problems you may have before you renew your driver's licence.You
            will receive an official certificate stating that you have had your
            vision screened by an optometrist. The certificate indicates the
            condition of your vision and will be accepted by the licensing
            departments.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/drivers-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>SCREENING FOR SKIPPERS LICENCE</h2></div>
        <div class="col-md-4">
          <p>
            Policy regarding Eyesight Tests and Medical Examinations: All
            candidates for the Skipper Coastal and Skipper Offshore ≥9m are
            required to pass the prescribed SAMSA letter and lantern test which
            are carried out at certain SAMSA offices. (The reason being the
            portability of these certificates to >25GT vessels and subsequent
            career paths). All other candidates attempting the examinations as
            specified in this document are required to produce an eyesight test
            certificate issued either by a SAMSA office, or an optometrist who
            is a member of the South African Medical Association.
          </p>
          <p>
            The test certificate shall be valid for a period of one year. It
            must state: the date of examination; the name and address of the
            examining body or organization; the signature of the examiner;
            whether or not the candidate has passed or failed the lantern (or
            Ishihara) test specified below; and whether or not the candidate
            passed the letter test with or without aids to vision.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/pensioner_eye_test-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-4"><h2>PENSIONERS</h2></div>
        <div class="col-md-4">
          <p>
            Specials tailored specifically for qualifying pensioners
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/services/Eye-Exam-1-small.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
